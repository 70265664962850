body {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: #333; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; }

p {
  font-size: 12px; }

a:link,
a:visited {
  text-decoration: none;
  color: #08c; }

a:hover {
  text-decoration: underline; }

ul.menu li {
  list-style: none; }

.zone-header-wrapper {
  background-color: #08c; }

.zone-header {
  padding-bottom: 5px;
  padding-top: 2px;
  display: flex; }
  .zone-header a:link,
  .zone-header a:visited {
    color: #fff; }
  .zone-header .region-branding {
    flex: 2; }
  .zone-header .region-user-first {
    flex: 1; }

h1.site-name,
h2.site-name {
  font-size: 26px;
  margin-top: 0;
  text-transform: lowercase; }
  h1.site-name a,
  h2.site-name a {
    color: #fff; }
  h1.site-name a:hover,
  h2.site-name a:hover {
    text-decoration: none; }

.not-logged-in.page-user ul.tabs {
  display: none; }

.not-logged-in.page-user input {
  max-width: 100%; }

.form-type-password {
  display: flex;
  flex-direction: column;
  max-width: 17rem; }
  .form-type-password .password-strength {
    float: none;
    width: 13rem;
    order: 3;
    margin-top: 0; }
  .form-type-password .password-confirm {
    margin-top: 0; }

div.form-item div.password-suggestions {
  max-width: 17rem; }
  div.form-item div.password-suggestions li {
    margin-left: 1rem; }

#edit-security-questions .fieldset-wrapper {
  display: flex;
  flex-wrap: wrap; }
  #edit-security-questions .fieldset-wrapper .fieldset-description {
    flex: 1 0 100%; }

.block-user-menu {
  display: none; }
  @media (min-width: 740px) {
    .block-user-menu {
      display: inherit; } }
  .block-user-menu ul.menu {
    text-align: right;
    margin-top: 5px; }
  .block-user-menu ul.menu li {
    padding-top: 0;
    padding-right: 8px;
    font-size: 12px;
    font-weight: bold;
    display: inline;
    border-right: 1px solid #267499; }
  .block-user-menu ul.menu li.last {
    border-right: 0; }

.zone-menu-wrapper {
  background-image: url("images/fondo-menu.png");
  background-repeat: repeat-x;
  height: 2.25rem;
  border-bottom: 1px solid #d4d4d4; }

.block-main-menu {
  padding-top: .5rem;
  font-size: 14px; }
  .block-main-menu ul.menu {
    text-align: center; }
  .block-main-menu ul li {
    display: inline;
    margin-right: 30px; }
  @media (max-width: 739px) {
    .block-main-menu {
      padding-top: 0.25rem; }
      .block-main-menu ul {
        display: flex;
        justify-content: space-around; }
        .block-main-menu ul li {
          margin: 0;
          padding: 0; }
      .block-main-menu .images a,
      .block-main-menu .videos a,
      .block-main-menu .stories a,
      .block-main-menu .comments a,
      .block-main-menu .my-favourites a {
        font-family: FontAwesome;
        overflow: hidden;
        width: 1.5rem;
        height: 1.5rem;
        display: inline-block;
        text-decoration: none;
        color: #333; }
        .block-main-menu .images a.active,
        .block-main-menu .videos a.active,
        .block-main-menu .stories a.active,
        .block-main-menu .comments a.active,
        .block-main-menu .my-favourites a.active {
          color: #08c; }
        .block-main-menu .images a::before,
        .block-main-menu .videos a::before,
        .block-main-menu .stories a::before,
        .block-main-menu .comments a::before,
        .block-main-menu .my-favourites a::before {
          margin-right: 1rem;
          font-size: 1.25rem; }
      .block-main-menu .images a::before {
        content: "\f03e"; }
      .block-main-menu .videos a::before {
        content: "\f04b"; }
      .block-main-menu .stories a::before {
        content: "\f0f6"; }
      .block-main-menu .comments a::before {
        content: "\f075"; }
      .block-main-menu .my-favourites a::before {
        content: "\f004"; }
      .block-main-menu .my-images {
        display: none; } }

.mobile-menu-icon {
  color: #fff;
  text-align: right;
  font-size: 1.25rem;
  cursor: pointer; }
  @media (min-width: 740px) {
    .mobile-menu-icon {
      display: none; } }

.region-preface-first.region-preface-first {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.2); }
  @media (min-width: 740px) {
    .region-preface-first.region-preface-first {
      display: none; } }
  .region-preface-first.region-preface-first.display {
    display: initial; }
  .region-preface-first.region-preface-first .region-inner {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 13rem;
    height: 100%; }

.mobile-menu-close {
  color: #666;
  text-align: right;
  font-size: 1.5rem;
  padding: 0.5rem 0.75rem;
  background-color: #fff;
  z-index: 1000; }

.mobile-menu {
  flex: 1;
  background-color: #fff; }
  .mobile-menu ul li {
    text-align: right;
    margin-right: 0.5rem;
    font-size: 1.25rem; }
    .mobile-menu ul li a {
      text-decoration: none; }
  .mobile-menu .submit a {
    color: #D87400; }
  .mobile-menu .donate a {
    color: #fc303a; }
  .mobile-menu .my-account,
  .mobile-menu .logout {
    border-top: 1px solid #666;
    margin-top: 0.25rem; }

.mobile-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 900; }

/* CONTENT
  =======================================*/
.zone-content-wrapper {
  background-color: #fff; }

.zone-content {
  padding-top: 10px;
  padding-bottom: 5px;
  min-height: 400px; }

h1.title {
  font-size: 24px; }

.welcome-not-logged-in {
  text-align: center;
  margin: 1rem;
  display: flex;
  justify-content: center; }
  @media (min-width: 740px) {
    .welcome-not-logged-in {
      margin: 2rem 1rem; } }
  .welcome-not-logged-in h2 {
    color: #08c;
    max-width: 30rem; }

.block-login {
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px; }

.block-login form {
  background-color: #eee;
  padding: 5px 20px;
  border-radius: 20px; }

.block-login label {
  padding-left: 5px; }

.block-login .form-required {
  display: none; }

.block-login input[type="text"],
.block-login input[type="password"] {
  width: 100%;
  font-size: 18px;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px; }

.block-login .form-actions {
  text-align: center; }

.block-login .item-list ul li {
  margin-left: 0;
  text-align: center; }

/* Views
  -------------------------------------*/
.view-footer-count {
  margin-top: 10px;
  text-align: center;
  font-weight: bold; }

.view-content .statistics .comments {
  margin-right: 8px; }

/* View filters
  -------------------------------------*/
.view-filters {
  border-radius: 15px;
  background-color: #f0f0f0;
  padding-top: 3px;
  padding-left: 45px;
  padding-bottom: 5px;
  margin-bottom: 10px; }

.view-filters label {
  color: #1184be;
  margin-left: 3px; }

.view-filters .views-exposed-widget {
  margin-right: 15px; }

.view-filters .form-submit {
  padding-left: 25px;
  padding-right: 25px;
  border: 0;
  background: none;
  background-color: #08c;
  text-shadow: none;
  color: #fff;
  border-radius: 8px; }

.view-image-video .view-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -0.5rem;
  margin-right: -0.5rem; }
  .view-image-video .view-content .views-row {
    flex: 1;
    margin-left: 0.5rem;
    margin-right: 0.5rem; }

.view-image-video .views-view-grid {
  border: 0; }

tr:hover td,
tr.even:hover td.active,
tr.odd:hover td.active {
  background: #FFF; }

.view-image-video td {
  vertical-align: top;
  width: 236px;
  padding-bottom: 20px; }

.view-image-video .views-field-field-video {
  background-color: #f0f0f0;
  text-align: center; }

.view-image-video .views-field-field-image {
  background-color: #f0f0f0;
  text-align: center; }

.view-image-video .statistics {
  background-color: #08c;
  color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0 10px;
  margin-bottom: 5px;
  text-align: right; }

.view-image-video .views-field-body {
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px; }

.view-image-video .views-field-field-cast-type,
.view-image-video .views-field-field-gender,
.view-image-video .views-field-field-image-type {
  font-weight: bold;
  color: #666; }

.view-stories .view-content {
  margin-top: 1.25rem; }

.view-stories .views-row {
  background-color: #ebebeb;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column-reverse; }
  @media (min-width: 740px) {
    .view-stories .views-row {
      flex-direction: initial; } }

.view-stories .group1 {
  background-color: #08c;
  color: #fff;
  padding: .5rem 1rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  vertical-align: top; }
  @media (min-width: 740px) {
    .view-stories .group1 {
      flex: 0 0 210px;
      border-top-left-radius: 0.5rem;
      border-bottom-right-radius: 0; } }

.view-stories .group2 {
  padding: 0.5rem;
  vertical-align: top; }

.view-stories .group2 .title {
  font-weight: bold; }

.view-stories .group2 .description {
  font-size: 12px; }

.view-comments .views-row {
  background-color: #ebebeb;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 25rem; }
  @media (min-width: 740px) {
    .view-comments .views-row {
      display: flex;
      max-width: initial; } }

.view-comments .views-row span {
  font-size: 0.875rem; }

.view-comments .description {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  background-color: #08c;
  color: #fff;
  padding: 0.5rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding-left: 15px;
  padding-right: 15px; }
  .view-comments .description a {
    color: #fff;
    font-weight: 700;
    font-size: 1.125rem; }
  @media (min-width: 740px) {
    .view-comments .description {
      flex-direction: inherit;
      width: 210px;
      min-width: 210px;
      border-bottom-left-radius: 0.5rem;
      border-top-right-radius: 0; } }

.view-comments .field-image,
.view-comments .field-video {
  width: 100px;
  text-align: center; }

.view-comments .comment {
  padding: 0.5rem;
  margin: 0 !important; }
  .view-comments .comment .field-name span {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 14px; }
  .view-comments .comment .field-created {
    line-height: 0.5rem; }
  .view-comments .comment .field-created span {
    font-size: 11px;
    margin-left: 5px; }
  .view-comments .comment .field-comment-body {
    margin-top: 0.5rem; }

.item-list .pager {
  display: flex;
  justify-content: center;
  margin-top: 1.25rem; }
  .item-list .pager.clearfix::after {
    display: none; }
  .item-list .pager li {
    margin: 0 .75rem;
    padding: 0; }
    .item-list .pager li a {
      text-decoration: none; }
  @media (max-width: 540px) {
    .item-list .pager .pager-ellipsis {
      display: none; } }

/* View banner
  -------------------------------------*/
.view-banner {
  border-bottom: 1px dotted #ccc;
  padding: 5px;
  text-align: center;
  margin-bottom: 5px; }

.view-banner .views-field-body {
  font-weight: normal; }

.view-banner .views-field-body .title {
  font-size: 16px;
  font-weight: bold; }

.view-banner .button {
  margin-top: 10px;
  margin-bottom: 10px; }

.view-banner .button a {
  background-color: #ff8900;
  padding: 4px 20px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  letter-spacing: 2px; }

.view-banner .button a:hover {
  text-decoration: none;
  background-color: #D87400; }

.submit-buttons {
  margin: 1rem 1rem 1.5rem; }
  .submit-buttons ul.menu {
    display: flex;
    justify-content: space-around; }
    .submit-buttons ul.menu li {
      display: none; }
      @media (min-width: 740px) {
        .submit-buttons ul.menu li {
          display: initial; } }
      .submit-buttons ul.menu li.donate {
        display: initial; }
    .submit-buttons ul.menu a {
      color: #fff;
      text-decoration: none;
      background-color: #ff8900;
      padding: .25rem 1.5rem;
      border-radius: 1rem;
      font-weight: bold; }
      .submit-buttons ul.menu a:hover {
        background-color: #D87400; }
    .submit-buttons ul.menu a.donate,
    .submit-buttons ul.menu a.donate:hover {
      background-color: #fc303a; }

.banner {
  border: 0;
  padding: 5px;
  text-align: center;
  margin-bottom: 5px; }
  .banner .title {
    font-size: 16px;
    font-weight: bold; }
  .banner .button {
    margin-top: 10px;
    margin-bottom: 10px; }
  .banner .button a {
    background-color: #ff8900;
    padding: 4px 20px;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 2px; }
  .banner .button a:hover {
    text-decoration: none;
    background-color: #D87400; }

.node .statistics_counter {
  display: none; }

.node ul.links {
  display: flex;
  flex-direction: column;
  margin-bottom: 0; }
  .node ul.links li {
    margin-bottom: 1rem; }

.node .flag-favourites {
  order: 0; }

.node .privatemsg_link {
  order: 1; }

.node .flag-favourites a {
  color: #fff;
  text-decoration: none;
  background-color: #08c;
  padding: .25rem 1.5rem;
  border-radius: 1rem;
  font-weight: bold; }
  .node .flag-favourites a:hover {
    background-color: #267499; }

.node .report-content {
  margin-top: 1rem; }

.node .report-button {
  color: #fff;
  text-decoration: none;
  background-color: #08c;
  padding: .25rem 1.5rem;
  border-radius: 1rem;
  font-weight: bold; }
  .node .report-button:hover {
    background-color: #267499; }

.node .report-desscription {
  margin-bottom: 0.5rem; }

.node-image-video {
  margin-top: 10px; }
  .node-image-video .content {
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 640px) {
      .node-image-video .content {
        flex-wrap: nowrap; } }
    .node-image-video .content .field-name-field-image,
    .node-image-video .content .field-name-field-video {
      flex: 1 0 100%;
      text-align: center; }
      .node-image-video .content .field-name-field-image iframe,
      .node-image-video .content .field-name-field-video iframe {
        width: 100%; }
      @media (min-width: 640px) {
        .node-image-video .content .field-name-field-image,
        .node-image-video .content .field-name-field-video {
          flex: 6; } }
      .node-image-video .content .field-name-field-image a,
      .node-image-video .content .field-name-field-video a {
        display: block; }
      .node-image-video .content .field-name-field-image img,
      .node-image-video .content .field-name-field-video img {
        max-width: 100%;
        height: auto; }
    .node-image-video .content .image-data {
      flex: 1;
      margin-top: 1rem; }
      @media (min-width: 640px) {
        .node-image-video .content .image-data {
          flex: 4;
          margin-left: 1rem;
          margin-top: 0; } }
  .node-image-video .user-post-info {
    display: flex;
    margin-bottom: 1.5rem; }
  .node-image-video .user-picture img {
    width: 60px;
    margin-right: 0.75rem; }
  .node-image-video .user-link {
    font-size: 1.125rem;
    font-weight: bold; }
  .node-image-video .image-description {
    margin-bottom: 1.5rem; }
  .node-image-video .field-name-body {
    line-height: 1.125;
    margin-bottom: 0.75rem; }
  .node-image-video .field-name-field-cast-type,
  .node-image-video .field-name-field-gender,
  .node-image-video .field-name-field-image-type {
    color: #666;
    font-size: 0.875rem;
    font-weight: bold; }
  .node-image-video .node-links ul {
    display: flex;
    flex-wrap: wrap; }
  .node-image-video .node-links li {
    padding: 0; }
  .node-image-video .node-links .comment-add {
    display: none; }
  .node-image-video .comment {
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid #eee;
    display: flex; }
  .node-image-video .comment-wrapper .comment-submitted .user-link {
    font-size: 18px;
    line-height: 18px; }
  .node-image-video .comment-wrapper .comment-content {
    font-size: 13px;
    margin-bottom: 10px; }
  .node-image-video .comment-wrapper .comment-date {
    font-size: 11px;
    display: block;
    line-height: 11px;
    margin-bottom: 3px; }

.node-story {
  margin-top: 10px; }
  @media (min-width: 980px) {
    .node-story .content {
      display: flex;
      flex-direction: row-reverse; }
    .node-story .story-content {
      flex: 7;
      margin-right: 1rem; }
    .node-story .story-data {
      flex: 3; } }
  .node-story .user-post-info {
    display: flex;
    margin-bottom: 1.5rem; }
  .node-story .user-picture img {
    width: 60px;
    margin-right: 10px; }
  .node-story .story-description {
    margin-bottom: 1.5rem; }
  .node-story .title {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 20px;
    border-bottom: 1px solid #ccc; }
  .node-story p {
    text-align: justify;
    font-size: 13px;
    line-height: 16px; }
  .node-story h1 {
    font-size: 18px;
    line-height: 20px; }
  .node-story h2 {
    font-size: 16px;
    line-height: 18px; }
  .node-story h3 {
    font-size: 14px;
    line-height: 16px; }
  .node-story .user-link {
    font-size: 1.125rem;
    font-weight: bold; }
  .node-story .report-content {
    margin-top: 10px;
    margin-bottom: 20px; }
  .node-story .field-name-body {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px; }
  .node-story .field-name-field-cast-type,
  .node-story .field-name-field-gender,
  .node-story .field-name-field-image-type,
  .node-story .field-name-field-story-type,
  .node-story .field-name-field-language {
    color: #666;
    font-size: 13px;
    font-weight: bold; }
  .node-story .field-name-field-image,
  .node-story .field-name-field-video {
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center; }
  .node-story .node-links ul {
    display: flex;
    flex-wrap: wrap; }
  .node-story .node-links li {
    padding: 0; }
  .node-story .node-links .comment-add {
    display: none; }
  .node-story .comment {
    margin-bottom: 10px;
    padding-bottom: 5px;
    border-bottom: 1px solid #eee;
    display: flex; }
  .node-story .comment-wrapper .comment-submitted .user-link {
    font-size: 18px;
    line-height: 18px; }
  .node-story .comment-wrapper .comment-content {
    font-size: 13px;
    margin-bottom: 10px; }
  .node-story .comment-wrapper .comment-date {
    font-size: 11px;
    display: block;
    line-height: 11px;
    margin-bottom: 3px; }

.node-image-form,
.node-video-form {
  font-size: 0.875rem; }
  .node-image-form .form-wrapper,
  .node-video-form .form-wrapper {
    margin-bottom: 0; }
  .node-image-form .field-name-field-cast-type .form-checkboxes,
  .node-video-form .field-name-field-cast-type .form-checkboxes {
    display: flex;
    flex-wrap: wrap; }
    .node-image-form .field-name-field-cast-type .form-checkboxes .form-item,
    .node-video-form .field-name-field-cast-type .form-checkboxes .form-item {
      flex: 0 0 6.5rem; }
  .node-image-form > div,
  .node-video-form > div {
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.5rem; }
    .node-image-form > div .field-name-field-image,
    .node-video-form > div .field-name-field-image {
      flex: 1; }
    .node-image-form > div .field-name-body,
    .node-video-form > div .field-name-body {
      flex: 1 1 29rem; }
    .node-image-form > div .field-name-field-cast-type,
    .node-video-form > div .field-name-field-cast-type {
      flex: 1 1 32rem; }
    .node-image-form > div .field-name-field-gender,
    .node-video-form > div .field-name-field-gender {
      flex: 1 0 5.5rem; }
    .node-image-form > div .field-name-field-image-type,
    .node-video-form > div .field-name-field-image-type {
      flex: 1 0 12rem; }
    .node-image-form > div .form-actions,
    .node-video-form > div .form-actions {
      flex: 1 0 100%; }
    .node-image-form > div > .form-wrapper:not(.form-actions),
    .node-video-form > div > .form-wrapper:not(.form-actions) {
      background-color: #eee;
      padding: 0 0.75rem;
      border-radius: 5px;
      margin-top: 0.75rem;
      margin-right: 0.5rem; }
  .node-image-form .image-widget-data .form-submit,
  .node-video-form .image-widget-data .form-submit {
    display: none; }

.node-video-form .field-name-field-video {
  flex: 1 15rem; }
  .node-video-form .field-name-field-video input {
    width: 100%; }
  .node-video-form .field-name-field-video .form-wrapper {
    margin-top: 1rem;
    font-weight: bold; }

.node-video-form .field-name-field-cast-type {
  flex: 1 1 15rem; }

.node-story-form {
  font-size: 0.875rem; }
  .node-story-form .form-wrapper {
    margin-bottom: 0; }
  .node-story-form .field-name-field-cast-type .form-checkboxes {
    display: flex;
    flex-wrap: wrap; }
    .node-story-form .field-name-field-cast-type .form-checkboxes .form-item {
      flex: 0 0 6.5rem; }
  .node-story-form .filter-wrapper,
  .node-story-form .ckeditor_links {
    display: none !important; }
  .node-story-form > div {
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.5rem; }
    .node-story-form > div .group-story-1 {
      margin-right: 0.5rem; }
      .node-story-form > div .group-story-1 .form-text {
        width: 100%; }
    .node-story-form > div .field-name-field-cast-type {
      flex: 1 1 100%; }
    .node-story-form > div .group-story-1,
    .node-story-form > div .field-name-field-gender,
    .node-story-form > div .field-name-field-image-type,
    .node-story-form > div .field-name-field-story-type,
    .node-story-form > div .field-name-field-language {
      flex: 1 0 10rem; }
    .node-story-form > div .form-actions {
      flex: 1 0 100%; }
    .node-story-form > div > .form-wrapper:not(.form-actions) {
      background-color: #eee;
      padding: 0 0.75rem;
      border-radius: 5px;
      margin-top: 0.75rem;
      margin-right: 0.5rem; }

.post-rules h2 {
  font-size: 1.125rem;
  margin-bottom: 0.5rem; }

.post-rules ul {
  margin-bottom: 0.5rem; }

.post-rules li {
  font-size: 0.875rem;
  margin-left: 2rem; }

.post-rules p {
  font-size: 0.875rem;
  margin-bottom: 0.5rem; }

/* PRIVATE MESSAGES MODULE
  =======================================*/
.page-messages ul.action-links li {
  list-style: none; }

/* REGISTER
  =======================================*/
#user-register-form .fieldset-legend {
  font-weight: bold; }

#edit-security-questions .fieldset-description {
  margin-bottom: 10px; }

#edit-security-questions img {
  display: inline-block;
  margin-right: 10px;
  vertical-align: top; }

#edit-security-questions .form-radios {
  display: inline-block;
  margin-right: 50px;
  vertical-align: top; }

#edit-security-questions .form-type-radio {
  margin-top: 0;
  margin-bottom: 4px; }

#edit-security-questions input[type=radio] {
  display: none; }

#edit-security-questions label {
  border: 1px solid #ccc;
  padding: 0 10px;
  margin-right: -5px;
  cursor: pointer;
  border-radius: 10px;
  width: 20px;
  display: inline-block;
  font-weight: bold; }

#edit-security-questions input:checked + label {
  background-color: #08c;
  color: #fff; }

#user-register-form #edit-field-cast-type-und .form-type-checkbox {
  display: inline-block;
  width: 120px; }

#user-register-form #edit-field-cast-type-und .form-type-checkbox input[type=checkbox] {
  display: none; }

#user-register-form #edit-field-cast-type-und .form-type-checkbox label {
  border: 1px solid #ccc;
  padding: 0 10px;
  margin-right: -5px;
  cursor: pointer;
  border-radius: 10px;
  width: 80px;
  display: inline-block;
  font-weight: bold;
  text-align: center; }

#user-register-form #edit-field-cast-type-und .form-type-checkbox input:checked + label {
  background-color: #08c;
  color: #fff; }

.zone-footer-wrapper {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px; }

.zone-footer {
  border-top: 1px solid #ddd;
  padding-top: 15px; }

.block-menu-footer ul li {
  display: inline; }

.block-menu-footer ul.menu {
  text-align: center; }

.block-menu-footer ul.menu li {
  padding-top: 0;
  font-size: 12px; }
